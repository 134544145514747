export class UsersClient {

    public static usersRoot = `${process.env.REACT_APP_API_URL}/v1/users`;

    private static firebaseUuidDest = "/by-firebase-uid"

    public static getUsersUrl() {
        return new URL(`${this.usersRoot}`);
    }

    public static getUserFirebaseUidUrl(firebaseUid: string) {
        return new URL(`${this.usersRoot}${this.firebaseUuidDest}/${firebaseUid}`);
    }
}
