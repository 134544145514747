import {BaseService} from "./BaseService";
import {FantasyLeaguesClient} from "../clients/FantasyLeaguesClient";
import {MFantasyLeague} from "../model/entities/MFantasyLeague";
import {ImportLeagueBody} from "../model/requests/ImportLeagueBody";
import {StandingBody} from "../model/requests/StandingBody";

export class FantasyLeaguesService extends BaseService {

    // Bylaws

    public static getBylaws(keyName: string) {
        return fetch(new URL(`https://api.github.com/repos/salexp/fantasy_resources/contents/${keyName}/bylaws.md`));
    }

    // Draft Picks

    public static getDraftPicks(leagueId: string, rounds: number, year: string | undefined) {
        return this.getRequest(FantasyLeaguesClient.getDraftUrl(leagueId, undefined, rounds.toString(), year));
    }

    public static getDraftPicksForManager(leagueId: string, managerId: string, rounds: number, year: string | undefined) {
        return this.getRequest(FantasyLeaguesClient.getDraftUrl(leagueId, managerId, rounds.toString(), year));
    }

    // Latest Week

    public static getLatestScoredWeek(leagueId: string) {
        return this.getRequest(FantasyLeaguesClient.getLatestScoredWeekUrl(leagueId));
    }

    // Leagues

    public static getAllLeagues() {
        return this.getRequest(FantasyLeaguesClient.getLeaguesUrl());
    }

    public static getLeague(leagueId: string) {
        return this.getRequest(FantasyLeaguesClient.getLeagueUrl(leagueId));
    }

    public static patchLeague(league: MFantasyLeague) {
        return this.patchRequest(FantasyLeaguesClient.getLeagueUrl(league.id.toString()), league);
    }

    public static putImport(platform: string, platformKey: string) {
        let newLeague: ImportLeagueBody = {
            platform: platform,
            platformKey: platformKey
        }
        return this.putRequest(FantasyLeaguesClient.getImportUrl(), newLeague);
    }

    // League Records

    public static getLeagueRecordBook(leagueId: string, limit: number) {
        return this.getRequest(FantasyLeaguesClient.getRecordBookUrl(leagueId, limit.toString()))
    }

    public static getLeagueRecordBookManagerRecords(leagueId: string) {
        return this.getRequest(FantasyLeaguesClient.getRecordBookManagersUrl(leagueId))
    }

    public static getLeagueRecordBookSeasons(leagueId: string, limit: number) {
        return this.getRequest(FantasyLeaguesClient.getRecordBookSeasonsUrl(leagueId, limit.toString()))
    }

    public static getLeagueRecordBookWeeks(leagueId: string, limit: number) {
        return this.getRequest(FantasyLeaguesClient.getRecordBookWeeksUrl(leagueId, limit.toString()))
    }

    // Managers

    public static getAllManagers(leagueId: string) {
        return this.getRequest(FantasyLeaguesClient.getManagersUrl(leagueId));
    }

    // Season Recaps

    public static getSeasonRecaps(leagueId: string) {
        return this.getRequest(FantasyLeaguesClient.getSeasonRecapsUrl(leagueId));
    }

    // Standings

    public static getStandings(leagueId: string) {
        return this.getRequest(FantasyLeaguesClient.getStandingsUrl(leagueId));
    }

    public static getStandingsYear(leagueId: string, year: string) {
        return this.getRequest(FantasyLeaguesClient.getStandingsYearUrl(leagueId, year));
    }

    public static putStandingsYear(leagueId: string, year: string, body: StandingBody[]) {
        return this.putRequest(FantasyLeaguesClient.getStandingsYearUrl(leagueId, year), body);
    }

    // Week Recaps

    public static getWeekRecap(leagueId: string, year: string, week: string, migrateIfNotExists: boolean) {
        return this.getRequest(FantasyLeaguesClient.getWeekRecapUrl(leagueId, year, week, migrateIfNotExists));
    }

    public static getWeekRecapWithEspn(leagueId: string, year: string, week: string,
                                       migrateIfNotExists: boolean, espnS2: string, espnSwid: string
    ) {
        return this.getRequest(FantasyLeaguesClient.getWeekRecapWithEspnUrl(leagueId, year, week, migrateIfNotExists, espnS2, espnSwid));
    }

    public static getWeekRecapLatestScored(leagueId: string, relativeWeek: number) {
        return this.getRequest(FantasyLeaguesClient.getWeekRecapLatestScoredUrl(leagueId, relativeWeek));
    }

}
