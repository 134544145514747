import {FantasyClient} from "./FantasyClient";

export class FantasyManagersClient {

    public static managersRoot = FantasyClient.fantasyRoot + "/managers";

    private static championshipsDest = "/championships";
    private static headToHeadDest = "/records/head-to-head";
    private static overallDest = "/records/overall";
    private static recordBookDest = "/records/record-book";
    private static seasonRecapDest = "/records/season-recaps"

    public static getChampionshipsUrl(managerId: string) {
        return new URL(`${this.managersRoot}/${managerId}${this.championshipsDest}`);
    }

    public static getHeadToHeadUrl(managerId: string) {
        return new URL(`${this.managersRoot}/${managerId}${this.headToHeadDest}`);
    }

    public static getOverallUrl(managerId: string) {
        return new URL(`${this.managersRoot}/${managerId}${this.overallDest}`);
    }

    public static getSeasonRecapUrl(managerId: string, years: []) {
        let url = new URL(`${this.managersRoot}/${managerId}${this.seasonRecapDest}`);
        years.forEach(y => url.searchParams.append("year", y));
        return url;
    }

    public static getRecordBookUrl(managerId: string, limit: string) {
        let url = new URL(`${this.managersRoot}/${managerId}${this.recordBookDest}`);
        url.searchParams.append("limit", limit);
        return url;
    }

}
