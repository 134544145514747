import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import DraftTablesComponent from "./components/DraftTables.component";
import PickTradesComponent from "./components/PickTrades.component";
import {NflService} from "../../service/NflService";

function fetchDraftYear(setSYear: ISetYear) {
    NflService.getDraftYear().then(res => res.json()).then((json: number) => {
        setSYear(json.toString());
    });
}

function renderLinks(leagueId: string, year: string, setYear: ISetYear) {
    return (
        <>
            <Link
                to={`/fantasy/${leagueId}/draft/${parseInt(year) - 1}`}
                onClick={() => setYear((parseInt(year) - 1).toString())}
            >Previous Year</Link>
            <Link
                to={`/fantasy/${leagueId}/draft/${parseInt(year) + 1}`}
                onClick={() => setYear((parseInt(year) + 1).toString())}
            >Next Year</Link>
        </>
    );
}

interface ISetYear extends React.Dispatch<React.SetStateAction<string | undefined>> { }

const FantasyLeagueDraft = () => {
    let { leagueId, year } = useParams();
    const [sYear, setSYear] = useState<string>();

    useEffect(() => {
        if (!year) {
            fetchDraftYear(setSYear);
        } else {
            setSYear(year);
        }
    }, [year]);

    return (
        <>
            {leagueId && sYear && renderLinks(leagueId, sYear, setSYear)}
            {sYear && <DraftTablesComponent leagueId={leagueId!} toggle={0} year={sYear!}/>}
            {sYear && <PickTradesComponent leagueId={leagueId!} toggle={0} year={sYear!}/>}
        </>
    );
};

export default FantasyLeagueDraft;
