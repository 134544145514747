export function ordinal(n: number) {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function range(start: number, end: number) {
    let len = end - start + 1;
    let a = new Array(len);
    for (let i = 0; i < len; i++) a[i] = start + i;
    return a;
}

// Modified from original at https://www.w3schools.com/howto/howto_js_sort_table.asp
export function sortTable(tableId: string, columnIndex: number, numeric: boolean) {
    function opposite() {
        return dir === "asc" ? "desc" : "asc";
    }

    var table, rows, switching, i, x, y, shouldSwitch, dir: string, switchcount = 0;
    table = document.getElementById(tableId) as HTMLTableElement;
    switching = true;

    dir = numeric ? "desc" : "asc";
    while (switching) {
        switching = false;
        rows = table?.rows;
        for (i = 1; i < (rows.length - 1); i++) {
            shouldSwitch = false;

            x = rows[i].getElementsByTagName("TD")[columnIndex];
            y = rows[i + 1].getElementsByTagName("TD")[columnIndex];

            if (dir === "asc") {
                if (
                    (!numeric && x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) ||
                    (numeric && Number(x.innerHTML) > Number(y.innerHTML))
                ) {
                    shouldSwitch = !numeric || (numeric && y.innerHTML !== '');
                    break;
                }
            } else if (dir === "desc") {
                if (
                    (!numeric && x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) ||
                    (numeric && Number(x.innerHTML) < Number(y.innerHTML))
                ) {
                    shouldSwitch = true;
                    break;
                }
            }
        }
        if (shouldSwitch) {
            rows[i].parentNode?.insertBefore(rows[i + 1], rows[i]);

            if (!switching) {
                let headerCells = rows[0].getElementsByTagName("TH");
                for (var j = 0; j < (headerCells.length); j++) {
                    headerCells[j].className = j === columnIndex ? `sorted-${numeric ? dir : opposite()}` : 'sorted-none';
                }
            }

            switching = true;
            switchcount ++;
        } else {
            if (switchcount === 0 && ((numeric && dir === "desc") || (!numeric && dir === "asc"))) {
                dir = numeric ? "asc" : "desc";
                switching = true;
            }
        }
    }
}
