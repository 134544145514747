import {NavigateFunction, useLocation, useNavigate} from "react-router-dom";
import {UserContext, logoutUser} from "../../context/UserContext";
import {MUser} from "../../model/entities/MUser";
import {BsPersonCircle} from "react-icons/bs";
import {ReactNode, useEffect, useState} from "react";
import PopupMenuComponent from "../components/PopupMenu.component";
import AdminNavComponent from "../admin/components/AdminNav.component";
import FantasyLeagueNavComponent from "../fantasy/components/FantasyLeagueNav.component";
import MenuButtonComponent from "./components/MenuButtonComponent";

const homeRoute = "/";
const fantasyRoute = "/fantasy";
const accountRoute = "my-account";
const adminRoute = "/admin";

const hoverClassName = "hover-primary-secondary";
const hoverClassNamePopup = "hover-mix-secondary";

const headerHeight = "60px";
const headerSubmenuHeight = "50px";
const headerTotalHeight = "110px";
const footerHeight = (4 * 14).toString() + "px";

function renderMainMenu(user: MUser | null, setShowMenu: any, navigate: NavigateFunction) {

    return (
        <ul>
            <MenuButtonComponent className={hoverClassName} content={<div>Home</div>} onClick={() => navigate(homeRoute)} routeRegex={/^\/$/}/>
            <MenuButtonComponent className={hoverClassName} content={<div>Fantasy</div>} onClick={() => navigate(fantasyRoute)} routeRegex={/^\/fantasy/}/>
            {renderAdminLink(!!user?.isSiteAdmin, setShowMenu, navigate, true)}
        </ul>
    );
}

function renderProfileMenu(user: MUser | null, setUser: any, showMenu: boolean, setShowMenu: any, navigate: NavigateFunction, redirect: string) {
    const mouseLeaveHandler = () => {
        setShowMenu(false);
    }

    const profileContent =
        <div className={"profile-container"}>
            <BsPersonCircle className={"profile-image"}/>
            <div className={"profile-name"}>
                {!!user && user.displayName?.split(' ').at(0)}
            </div>
        </div>

    return (
        <>
            <ul onMouseLeave={mouseLeaveHandler}>
                <MenuButtonComponent className={hoverClassName} content={profileContent} onClick={() => setShowMenu(!showMenu)} routeRegex={null}/>
                {showMenu &&
                    <PopupMenuComponent className={"menu-profile-popup"} setShowMenu={setShowMenu} nodes={[
                        renderAccountLink(!!user, setShowMenu, navigate),
                        renderAdminLink(!!user?.isSiteAdmin, setShowMenu, navigate, false),
                        renderLogLink(!!user, redirect, setUser, setShowMenu, navigate)
                    ]}/>
                }
            </ul>
        </>
    );
}

function renderAccountLink(isLoggedIn: boolean, setShowMenu: any, navigate: NavigateFunction) {
    const clickHandler = () => {
        navigate(accountRoute);
        setShowMenu(false);
    }

    if (isLoggedIn) {
        return (
            <MenuButtonComponent className={hoverClassNamePopup} content={<div>Account Settings</div>} onClick={() => clickHandler()} routeRegex={null}/>
        )
    }

}

function renderAdminLink(isAdmin: boolean, setShowMenu: any, navigate: NavigateFunction, primaryStyle: boolean) {
    const clickHandler = () => {
        navigate(adminRoute);
        setShowMenu(false);
    }

    if (isAdmin) {
        return (
            <MenuButtonComponent
                className={primaryStyle ? hoverClassName : hoverClassNamePopup}
                content={<div>Admin</div>}
                onClick={() => clickHandler()}
                routeRegex={primaryStyle ? /^\/admin/ : null}
            />
        )
    }
}

function renderLogLink(isLoggedIn: boolean, redirect: string, setUser: any, setShowMenu: any, navigate: NavigateFunction) {
    const loginRoute = "/log-in?redirect=" + redirect;

    const clickHandler = (logout: boolean) => {
        if (logout) {
            logoutUser(setUser);
        }
        setShowMenu(false);
        navigate(logout ? homeRoute : loginRoute);
    }

    if (!isLoggedIn) {
        return (
            <MenuButtonComponent className={hoverClassNamePopup} content={<div>Login/Signup</div>} onClick={() => clickHandler(false)} routeRegex={null}/>
        )
    } else {
        return (
            <MenuButtonComponent className={hoverClassNamePopup} content={<div>Logout</div>} onClick={() => clickHandler(true)} routeRegex={null}/>
        )
    }
}

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [subMenu, setSubMenu] = useState<ReactNode>(null)

    useEffect(() => {
        if (/^\/admin/.test(location.pathname)) {
            setSubMenu(<AdminNavComponent/>)
        } else if (/^\/fantasy/.test(location.pathname)) {
            setSubMenu(<FantasyLeagueNavComponent/>)
        } else {
            setSubMenu(null);
        }
    }, [navigate]);

    useEffect(() => {
        let headerStyle = document.getElementById('header')?.style!;
        let submenuStyle = document.getElementById('menu-submenu')?.style!;
        let submenuNavStyle = document.getElementById('menu-submenu-nav')?.style!;
        let mainStyle = document.getElementById('main')?.style!;

        headerStyle.height = subMenu === null ? headerHeight : headerTotalHeight;
        submenuStyle.height = subMenu === null ? '0' : headerSubmenuHeight;
        submenuNavStyle.height = subMenu === null ? '0' : headerSubmenuHeight;

        mainStyle.paddingTop = subMenu === null ? headerHeight : headerTotalHeight;
        mainStyle.minHeight = `calc(100vh - ${mainStyle.paddingTop} - ${footerHeight})`;
    }, [subMenu]);

    return (
        <UserContext.Consumer>
            {({user, setUser}) => {
                return (
                    <header id={'header'}>
                        <div id={"menu-menu"}>
                            <nav id={"menu-main"}>
                                {renderMainMenu(user, setShowMenu, navigate)}
                            </nav>
                            <nav id={"menu-profile"}>
                                {renderProfileMenu(user, setUser, showMenu, setShowMenu, navigate, location.pathname)}
                            </nav>
                        </div>
                        <div id={"menu-submenu"}>
                            <nav id={"menu-submenu-nav"}>
                                {subMenu}
                            </nav>
                        </div>
                    </header>
                )
            }}
        </UserContext.Consumer>
    )
};

export default Header;
