import {BaseService} from "./BaseService";
import {FantasyClient} from "../clients/FantasyClient";
import {DraftPickTradeBody} from "../model/requests/DraftPickTradeBody";

export class FantasyService extends BaseService {

    public static getLeaguePlatforms() {
        return this.getRequest(FantasyClient.getLeaguePlatformsUrl());
    }

    public static getDraftPickTrades(leagueId: string, startYear: string | null, endYear: string | null) {
        return this.getRequest(FantasyClient.getDraftPickTradesUrlParams(leagueId, startYear, endYear));
    }

    public static putDraftPickTrade(trade: DraftPickTradeBody) {
        return this.putRequest(FantasyClient.getDraftPickTradesUrl(), trade);
    }

}
