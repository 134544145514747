import {useNavigate} from "react-router-dom";
import MenuButtonComponent from "../../layout/components/MenuButtonComponent";

const AdminNavComponent = () => {
    let navigate = useNavigate();

    const draftPickTradeRoute = "/admin/draft-pick-trades";
    const leagueSettingRoute = "/admin/league-settings";

    const hoverClassName = "hover-white-secondary";

    return (
        <ul>
            <MenuButtonComponent className={hoverClassName} onClick={() => navigate(draftPickTradeRoute)} content={<div>Draft Pick Trades</div>} routeRegex={/^\/admin\/draft-pick-trades$/}/>
            <MenuButtonComponent className={hoverClassName} onClick={() => navigate(leagueSettingRoute)} content={<div>League Settings</div>} routeRegex={/^\/admin\/league-settings$/}/>
        </ul>
    )
};

export default AdminNavComponent;
