import React from 'react';
import {MUser} from "../model/entities/MUser";
import {UserBody} from "../model/requests/UserBody";
import {UsersService} from "../service/UsersService";
import {Logger} from "../../Logger";
import {signOut} from "firebase/auth";
import {firebaseAuth} from "../../firebase";

interface IUserContext {
    user: MUser | null;
    setUser: any;
}

const UserContext = React.createContext<IUserContext>({
    user: null,
    setUser: () => {}
});

let loggingInBlocked = false;

export async function loginUser(fbUser: any, setUser: any) {
    if (fbUser?.uid && !loggingInBlocked) {
        loggingInBlocked = true;

        let user: UserBody = {
            displayName: fbUser.displayName,
            firebaseUid: fbUser.uid
        }

        UsersService.putUser(user)
            .then(res => res.json())
            .then(json => setUser(json))
            .then(_ => Logger.debug('logged-in success'))
            .then(_ => loggingInBlocked = false)
            .catch(err => {
                Logger.info(err);
                loggingInBlocked = false;
            })
    } else if (loggingInBlocked) {
        Logger.debug('logging-in blocked');
    }
}

export async function logoutUser(setUser: any) {
    await signOut(firebaseAuth);
    setUser(null);
    Logger.debug('logged-out success');
}

export { UserContext };
