export class FantasyClient {

    public static fantasyRoot = `${process.env.REACT_APP_API_URL}/v1/fantasy`;

    private static draftPickTradesDest = "/draft-pick-trades"
    private static leaguePlatformsDest = "/league-platforms"

    public static getDraftPickTradesUrl() {
        return new URL(`${this.fantasyRoot}${this.draftPickTradesDest}`);
    }

    public static getDraftPickTradesUrlParams(leagueId: string, startYear: string | null, endYear: string | null) {
        let url = this.getDraftPickTradesUrl();
        url.searchParams.append("leagueId", leagueId);
        if (!!startYear) {
            url.searchParams.append("startYear", startYear);
        }
        if (!!endYear) {
            url.searchParams.append("endYear", endYear);
        }
        return url;
    }

    public static getLeaguePlatformsUrl() {
        return new URL(`${this.fantasyRoot}${this.leaguePlatformsDest}`);
    }
}
