import {UsersClient} from "../clients/UsersClient";
import {BaseService} from "./BaseService";
import {UserBody} from "../model/requests/UserBody";

export class UsersService extends BaseService {

    public static getUserByFirebaseUid(firebaseUid: string) {
        return this.getRequest(UsersClient.getUserFirebaseUidUrl(firebaseUid));
    }

    public static putUser(user: UserBody) {
        return this.putRequest(UsersClient.getUsersUrl(), user);
    }

}
