import {Navigate} from 'react-router-dom';
import {ComponentType, FC, useContext} from "react";
import AccessDenied from "./AccessDenied";
import {UserContext} from "../context/UserContext";

export enum ROLE {
    User,
    Admin
}

interface Props {
    component: ComponentType;
    path?: string;
    roles: Array<ROLE>;
}

export const PrivateRoute: FC<Props> = ({ component: RouteComponent, roles }) => {
    const {user, setUser} = useContext(UserContext)

    const isAuthenticated = !!user;
    const userHasRequiredRole = (
        (roles.includes(ROLE.User) && !!user) ||
        (roles.includes(ROLE.Admin) && user?.isSiteAdmin)
    );

    if (!isAuthenticated) {
        return <Navigate to="/log-in" />;
    }

    if (isAuthenticated && !userHasRequiredRole) {
        return <AccessDenied />;
    }

    if (isAuthenticated && userHasRequiredRole) {
        return <RouteComponent />;
    }

    return <Navigate to="/" />;
}
