export class Logger {

    public static debug(message: string) {
        if (process.env.REACT_APP_ENV === "dev") {
            console.log("[DEBUG] " + message);
        }
    }

    public static error(message: string) {
        console.log("[ERROR] " + message);
    }

    public static info(message: string) {
        console.log("[INFO] " + message);
    }

}