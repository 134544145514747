import {Link} from "react-router-dom";
import {LIB_DATE} from "../../../env/date";
import {LIB_ENV} from "../../../env/environment";
import {LIB_VERSION} from '../../../env/version';
import {HomeService} from "../../service/HomeService";
import {useEffect, useState} from "react";
import {HealthResponse} from "../../model/responses/HealthResponse";

function fetchBackendHealth(setHealth: any) {
    HomeService.getHealth().then(response => response.json()).then(json => {
        setHealth(json);
    });
}

const Footer = () => {
    const [health, setHealth] = useState<HealthResponse>();

    useEffect(() => {
        fetchBackendHealth(setHealth);
    }, []);

    const legalItemClassName = 'legal-row-item'
    const versionItemClassName = 'version-row-item'

    return (
        <footer id={'footer'}>
            <div id={'legal-row'}>
                <Link to={'about'} className={legalItemClassName}>About</Link>
                <span className={legalItemClassName}>|</span>
                <Link to={'terms-of-use'} className={legalItemClassName}>Terms of Use</Link>
                <span className={legalItemClassName}>|</span>
                <Link to={'privacy-policy'} className={legalItemClassName}>Privacy Policy</Link>
                <span className={legalItemClassName}>|</span>
                <div className={legalItemClassName}>Copyright © 2022 Stuart Petty</div>
            </div>
            <div id={'footer-version-row'}>
                <div className={versionItemClassName}>{LIB_ENV}</div>
                <span className={versionItemClassName}>|</span>
                <div className={versionItemClassName}>{LIB_VERSION}</div>
                <span className={versionItemClassName}>|</span>
                <div className={versionItemClassName}>{health ? health.version : 'ERROR'}</div>
                <span className={versionItemClassName}>|</span>
                <div className={versionItemClassName}>{LIB_DATE}</div>
            </div>
        </footer>
    )
};

export default Footer;
