import {BaseService} from "./BaseService";
import {NflClient} from "../clients/NflClient";

export class NflService extends BaseService {

    public static getDraftYear() {
        return this.getRequest(NflClient.getScheduleYearCurrentUrl());
    }

}
