import {MFantasyMatchup} from "../../../model/entities/MFantasyMatchup";
import {MFantasyWeekRecap} from "../../../model/responses/MFantasyWeekRecap";
import React from "react";
import {MFantasyMatchupPerformance} from "../../../model/entities/MFantasyMatchupPerformance";

interface PSummaryTable {
    recaps: MFantasyWeekRecap[]
    title: string
    filter: (matchup: MFantasyMatchup) => Boolean
}

const MatchupsSummaryTableComponent = (props: PSummaryTable) => {
    let matchups = props.recaps
        .filter((item: MFantasyWeekRecap) => { return props.filter(item.matchup) })
        .map((m: MFantasyWeekRecap) => m.matchup);
    if (matchups.length > 0) {
        return (
            <>
                { renderMatchupsTable(matchups, props.title) }
            </>
        );
    } else {
        return (<></>);
    }

}

function renderMatchupsTable(matchups: MFantasyMatchup[], caption: string) {
    return (
        <>
            <table>
                <caption>{caption}</caption>
                <thead>
                <tr>
                    <th>Home</th>
                    <th>Score</th>
                    <th>Away</th>
                </tr>
                </thead>
                <tbody>
                {matchups.map((item: MFantasyMatchup) => (
                    renderMatchupsTableRow(item)
                ))}
                </tbody>
            </table>
        </>
    );
}

function renderMatchupsTableRow(item: MFantasyMatchup) {
    let awayPerformance = item.matchupPerformances.filter((mp: MFantasyMatchupPerformance) => {
        return mp.placement === "Away"
    })[0]
    let homePerformance = item.matchupPerformances.filter((mp: MFantasyMatchupPerformance) => {
        return mp.placement === "Home"
    })[0]

    if (!homePerformance || !awayPerformance) {
        return;
    }

    return (
        <tr key={item.id}>
            <td style={{fontWeight: homePerformance.didWin ? "bold" : "normal"}}>
                {homePerformance.managerTeam.manager.displayName}
            </td>
            <td>
                {homePerformance.score.toFixed(2)} - {awayPerformance.score.toFixed(2)}
            </td>
            <td style={{fontWeight: awayPerformance.didWin ? "bold" : "normal"}}>
                {awayPerformance.managerTeam.manager.displayName}
            </td>
        </tr>
    );
}

export default MatchupsSummaryTableComponent;
