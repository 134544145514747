import {MFantasyRecordsPerfResponse} from "../../model/responses/MFantasyRecordsPerfResponse";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {MFantasyMatchupPerformance} from "../../model/entities/MFantasyMatchupPerformance";
import {FantasyLeaguesService} from "../../service/FantasyLeaguesService";
import {MFantasyRecordsSeasonResponse} from "../../model/responses/MFantasyRecordsSeasonResponse";
import {MFantasyRecordsIdvYearRecap} from "../../model/responses/MFantasyRecordsIdvYearRecap";
import {championshipStr, playoffsStr} from "./FantasyFormatting";
import {MFantasyRecordsWeekResponse} from "../../model/responses/MFantasyRecordsWeekResponse";
import {MFantasyRecordsWeek} from "../../model/responses/MFantasyRecordsWeek";
import PlaceholderTableComponent from "../placeholders/PlaceholderTable.component";

function fetchMatchupRecords(leagueId: string, setMatchupResponse: ISetMatchupRecords) {
    FantasyLeaguesService.getLeagueRecordBook(leagueId, 10).then(response => response.json()).then(json => {
        setMatchupResponse(json);
    });
}

function fetchSeasonRecords(leagueId: string, setSeasonRecords: ISetSeasonRecords) {
    FantasyLeaguesService.getLeagueRecordBookSeasons(leagueId, 10).then(response => response.json()).then(json => {
        setSeasonRecords(json);
    });
}

function fetchWeekRecords(leagueId: string, setWeekRecords: ISetWeekRecords) {
    FantasyLeaguesService.getLeagueRecordBookWeeks(leagueId, 10).then(response => response.json()).then(json => {
        setWeekRecords(json);
    });
}

function renderMatchupRecords(matchupResponse: MFantasyRecordsPerfResponse | undefined) {
    if (!matchupResponse) {
        return (
            <></>
        )
    } else {
        return (
            <>
                <h2>Game Performance Records</h2>
                <table>
                    <caption>Most Points</caption>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Score</th>
                            <th>Manager</th>
                            <th>Opponent</th>
                            <th>Year</th>
                            <th>Week</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchupResponse.mostPoints.map((item: MFantasyMatchupPerformance, i: number) => (
                            <tr key={item.id}>
                                <td className={'col-cell-rank'}>
                                    {i+1}
                                </td>
                                <td className={'right-cell'}>
                                    {item.score.toFixed(2)}
                                </td>
                                <td>
                                    {item.managerTeam.manager.displayName}
                                </td>
                                <td>
                                    {item.opponentTeam.manager.displayName}
                                </td>
                                <td className={'right-cell'}>
                                    {item.scheduleWeek.year}
                                </td>
                                <td className={'right-cell'}>
                                    {item.scheduleWeek.week}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <table>
                    <caption>Fewest Points</caption>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Score</th>
                            <th>Manager</th>
                            <th>Opponent</th>
                            <th>Year</th>
                            <th>Week</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchupResponse.fewestPoints.map((item: MFantasyMatchupPerformance, i: number) => (
                            <tr key={item.id}>
                                <td className={'col-cell-rank'}>
                                    {i+1}
                                </td>
                                <td className={'right-cell'}>
                                    {item.score.toFixed(2)}
                                </td>
                                <td>
                                    {item.managerTeam.manager.displayName}
                                </td>
                                <td>
                                    {item.opponentTeam.manager.displayName}
                                </td>
                                <td className={'right-cell'}>
                                    {item.scheduleWeek.year}
                                </td>
                                <td className={'right-cell'}>
                                    {item.scheduleWeek.week}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <table>
                    <caption>Largest Margin Win</caption>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Margin</th>
                            <th>Score</th>
                            <th>Manager</th>
                            <th>Opponent</th>
                            <th>Year</th>
                            <th>Week</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchupResponse.largestMarginWin.map((item: MFantasyMatchupPerformance, i: number) => (
                            <tr key={item.id}>
                                <td className={'col-cell-rank'}>
                                    {i+1}
                                </td>
                                <td className={'right-cell'}>
                                    {Math.abs(item.score - item.opponentScore).toFixed(2)}
                                </td>
                                <td>
                                    {item.score.toFixed(2)} - {item.opponentScore.toFixed(2)}
                                </td>
                                <td>
                                    {item.managerTeam.manager.displayName}
                                </td>
                                <td>
                                    {item.opponentTeam.manager.displayName}
                                </td>
                                <td className={'right-cell'}>
                                    {item.scheduleWeek.year}
                                </td>
                                <td className={'right-cell'}>
                                    {item.scheduleWeek.week}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <table>
                    <caption>Smallest Margin Loss/Tie</caption>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Margin</th>
                            <th>Score</th>
                            <th>Manager</th>
                            <th>Opponent</th>
                            <th>Year</th>
                            <th>Week</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchupResponse.smallestMarginLoss.map((item: MFantasyMatchupPerformance, i: number) => (
                            <tr key={item.id}>
                                <td className={'col-cell-rank'}>
                                    {i+1}
                                </td>
                                <td className={'right-cell'}>
                                    {(item.scoreDifference).toFixed(2)}
                                </td>
                                <td>
                                    {item.score.toFixed(2)} - {item.opponentScore.toFixed(2)}
                                </td>
                                <td>
                                    {item.managerTeam.manager.displayName}
                                </td>
                                <td>
                                    {item.opponentTeam.manager.displayName}
                                </td>
                                <td className={'right-cell'}>
                                    {item.scheduleWeek.year}
                                </td>
                                <td className={'right-cell'}>
                                    {item.scheduleWeek.week}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        )
    }
}

function renderSeasonRecords(seasonResponse: MFantasyRecordsSeasonResponse | undefined) {
    if (!seasonResponse) {
        return (
            <></>
        )
    } else {
        return (
            <>
                <h2>Season Records</h2>
                <table>
                    <caption>Most Points</caption>
                    <thead>
                    <tr>
                        <th>Rank</th>
                        <th>PF/G</th>
                        <th>Manager</th>
                        <th>Year</th>
                        <th>Playoffs</th>
                        <th>Championship</th>
                    </tr>
                    </thead>
                    <tbody>
                    {seasonResponse.mostPointsOverall.map((item: MFantasyRecordsIdvYearRecap, i: number) => (
                        <tr>
                            <td className={'col-cell-rank'}>
                                {i+1}
                            </td>
                            <td className={'right-cell'}>
                                {item.records.overallRecord.averagePointsFor.toFixed(2)}
                            </td>
                            <td>
                                {item.team.manager.displayName}
                            </td>
                            <td className={'right-cell'}>
                                {item.year}
                            </td>
                            <td>
                                {playoffsStr(item)}
                            </td>
                            <td>
                                {championshipStr(item, null)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <table>
                    <caption>Fewest Points</caption>
                    <thead>
                    <tr>
                        <th>Rank</th>
                        <th>PF/G</th>
                        <th>Manager</th>
                        <th>Year</th>
                    </tr>
                    </thead>
                    <tbody>
                    {seasonResponse.fewestPointsOverall.map((item: MFantasyRecordsIdvYearRecap, i: number) => (
                        <tr>
                            <td className={'col-cell-rank'}>
                                {i+1}
                            </td>
                            <td className={'right-cell'}>
                                {item.records.overallRecord.averagePointsFor.toFixed(2)}
                            </td>
                            <td>
                                {item.team.manager.displayName}
                            </td>
                            <td className={'right-cell'}>
                                {item.year}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }
}

function renderWeekRecords(weekResponse: MFantasyRecordsWeekResponse | undefined) {
    if (!weekResponse) {
        return (
            <></>
        )
    } else {
        return (
            <>
                <h2>Week Records</h2>
                <table>
                    <caption>Most Points</caption>
                    <thead>
                    <tr>
                        <th>Rank</th>
                        <th>PF/G</th>
                        <th>Year</th>
                        <th>Week</th>
                    </tr>
                    </thead>
                    <tbody>
                    {weekResponse.mostPoints.map((item: MFantasyRecordsWeek, i: number) => (
                        <tr>
                            <td className={'col-cell-rank'}>
                                {i+1}
                            </td>
                            <td className={'right-cell'}>
                                {item.averageScore.toFixed(2)}
                            </td>
                            <td className={'right-cell'}>
                                {item.scheduleWeek.year}
                            </td>
                            <td className={'right-cell'}>
                                {item.scheduleWeek.week}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <table>
                    <caption>Fewest Points</caption>
                    <thead>
                    <tr>
                        <th>Rank</th>
                        <th>PF/G</th>
                        <th>Manager</th>
                        <th>Year</th>
                    </tr>
                    </thead>
                    <tbody>
                    {weekResponse.fewestPoints.map((item: MFantasyRecordsWeek, i: number) => (
                        <tr>
                            <td className={'col-cell-rank'}>
                                {i+1}
                            </td>
                            <td className={'right-cell'}>
                                {item.averageScore.toFixed(2)}
                            </td>
                            <td className={'right-cell'}>
                                {item.scheduleWeek.year}
                            </td>
                            <td className={'right-cell'}>
                                {item.scheduleWeek.week}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }
}

interface ISetMatchupRecords extends React.Dispatch<React.SetStateAction<MFantasyRecordsPerfResponse | undefined>> { }
interface ISetSeasonRecords extends React.Dispatch<React.SetStateAction<MFantasyRecordsSeasonResponse | undefined>> { }
interface ISetWeekRecords extends React.Dispatch<React.SetStateAction<MFantasyRecordsWeekResponse | undefined>> { }

const FantasyLeagueRecordBook = () => {
    let { leagueId } = useParams();
    const [matchupResponse, setMatchupResponse] = useState<MFantasyRecordsPerfResponse>();
    const [seasonResponse, setSeasonResponse] = useState<MFantasyRecordsSeasonResponse>();
    const [weeksResponse, setWeeksResponse] = useState<MFantasyRecordsWeekResponse>();

    useEffect(() => {
        fetchMatchupRecords(leagueId!, setMatchupResponse);
        fetchSeasonRecords(leagueId!, setSeasonResponse);
        fetchWeekRecords(leagueId!, setWeeksResponse);
    }, [leagueId]);

    return (
        <>
            <>
                <PlaceholderTableComponent content={renderMatchupRecords(matchupResponse)} ready={!!matchupResponse}/>
                <PlaceholderTableComponent content={renderSeasonRecords(seasonResponse)} ready={!!seasonResponse}/>
                <PlaceholderTableComponent content={renderWeekRecords(weeksResponse)} ready={!!weeksResponse}/>
            </>
        </>
    );
};

export default FantasyLeagueRecordBook;
