import React, {useEffect, useState} from "react";
import {MFantasyLeague} from "../../model/entities/MFantasyLeague";
import {Logger} from "../../../Logger";
import {FantasyLeaguesService} from "../../service/FantasyLeaguesService";
import LeagueSelectComponent from "./components/LeagueSelect.component";
import {FantasyService} from "../../service/FantasyService";
import {Alert} from "../../../Alert";
import {YearWeek} from "../../model/YearWeek";
import SetStandingsComponent from "./components/SetStandings.component";

const espnS2Key = "espn-s2";
const espnSwidKey = "espn-swid";
const newPlatform = "new-platform";
const newPlatformKey = "new-platform-key";

function fetchLeaguePlatforms(setPlatforms: any) {
    FantasyService.getLeaguePlatforms().then(response => response.json()).then(json => {
        setPlatforms(json);
    });
}

function fetchLeagues(setLeagues: any) {
    FantasyLeaguesService.getAllLeagues().then(response => response.json()).then(json => {
        setLeagues(json);
    });
}

function fetchLatestScoredWeek(league: MFantasyLeague, setWeek: any, setYear: any) {
    FantasyLeaguesService.getLatestScoredWeek(league.id.toString()).then(response => response.json()).then((json: YearWeek) => {
        setWeek(json.week + 1);
        setYear(json.year);
    });
}

function patchLeague(league: MFantasyLeague) {
    FantasyLeaguesService.patchLeague(league)
        .then(res => {
            Alert.responseCode(res);
        })
        .catch(err => Logger.info('Error: ' + err));
}

function renderEspnParams() {
    function saveHandler() {
        let espnS2 = document.getElementById(espnS2Key) as HTMLInputElement;
        let espnSwid = document.getElementById(espnSwidKey) as HTMLInputElement;
        localStorage.setItem(espnS2Key, espnS2.value);
        localStorage.setItem(espnSwidKey, espnSwid.value);
    }

    return (
        <form>
            <label>ESPN S2: </label>
            <input type={"text"} id={espnS2Key} defaultValue={localStorage.getItem(espnS2Key) ?? ''}/>
            <label>ESPN SWID: </label>
            <input type={"text"} id={espnSwidKey} defaultValue={localStorage.getItem(espnSwidKey) ?? ''}/>
            <input type={'button'} onClick={saveHandler} value={"Save"}/>
        </form>
    )
}

function renderAddLeague(platforms: string[] | undefined, setLeagues: any) {

    function importHandler() {
        let platform = document.getElementById(newPlatform) as HTMLInputElement;
        let platformKey = document.getElementById(newPlatformKey) as HTMLInputElement;

        FantasyLeaguesService.putImport(platform.value, platformKey.value).then(res => {
            fetchLeagues(setLeagues);
            Alert.responseCode(res);
        });
    }

    if (platforms === undefined) {
        return <></>;
    }

    return (
        <>
            <label>Platform: </label>
            <select id={newPlatform}>
                <option disabled={true} key={null} selected={true} value={undefined}>Select Platform</option>
                {platforms.map((item: string) => {
                    return <option key={item} value={item}>{item}</option>
                })}
            </select>
            <label>Platform Key: </label>
            <input type={"text"} id={newPlatformKey}/>
            <input type={'button'} onClick={importHandler} value={"Import"}/>
        </>
    );
}

function renderLeague(league: MFantasyLeague | undefined, year: number | undefined, setWeek: any) {
    if (league === undefined) {
        return <></>;
    }

    return (
        <>
            { renderLeaguePages(league) }
            { renderWeekUpdater(league, setWeek) }
            <SetStandingsComponent league={league} initYear={year}/>
        </>
    );
}

function renderLeaguePages(league: MFantasyLeague) {

    const checkboxHandlerBylaws = (e: React.ChangeEvent<HTMLInputElement>, item: MFantasyLeague) => {
        item.hasBylaws = e.target.checked;
    };

    const checkboxHandlerDraftBoard = (e: React.ChangeEvent<HTMLInputElement>, item: MFantasyLeague) => {
        item.hasDraftBoard = e.target.checked;
    };

    const checkboxHandlerManagers = (e: React.ChangeEvent<HTMLInputElement>, item: MFantasyLeague) => {
        item.hasManagers = e.target.checked;
    };

    const checkboxHandlerRecordBook = (e: React.ChangeEvent<HTMLInputElement>, item: MFantasyLeague) => {
        item.hasRecordBook = e.target.checked;
    };

    const checkboxHandlerWeekRecaps = (e: React.ChangeEvent<HTMLInputElement>, item: MFantasyLeague) => {
        item.hasWeekRecaps = e.target.checked;
    };

    const buttonHandler = (item: MFantasyLeague) => {
        patchLeague(item);
    };

    return (
        <>
            <table id={"table-" + league.keyName}>
                <caption>League Pages</caption>
                <thead>
                    <tr>
                        <th>Bylaws Page</th>
                        <th>Draft Page</th>
                        <th>Managers Page</th>
                        <th>Records Page</th>
                        <th>Week Recaps Page</th>
                        <th>Patch</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input type={"checkbox"} defaultChecked={league.hasBylaws} onChange={(event) => checkboxHandlerBylaws(event, league)}/>
                        </td>
                        <td>
                            <input type={"checkbox"} defaultChecked={league.hasDraftBoard} onChange={(event) => checkboxHandlerDraftBoard(event, league)}/>
                        </td>
                        <td>
                            <input type={"checkbox"} defaultChecked={league.hasManagers} onChange={(event) => checkboxHandlerManagers(event, league)}/>
                        </td>
                        <td>
                            <input type={"checkbox"} defaultChecked={league.hasRecordBook} onChange={(event) => checkboxHandlerRecordBook(event, league)}/>
                        </td>
                        <td>
                            <input type={"checkbox"} defaultChecked={league.hasWeekRecaps} onChange={(event) => checkboxHandlerWeekRecaps(event, league)}/>
                        </td>
                        <td>
                            <input type={"button"} onClick={() => buttonHandler(league)} value={"PATCH"}/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

function renderWeekUpdater(league: MFantasyLeague, setWeek: any) {

    const buttonHandler = () => {

        let espnS2 = document.getElementById(espnS2Key) as HTMLInputElement;
        let espnSwid = document.getElementById(espnSwidKey) as HTMLInputElement;

        let recapYear = document.getElementById("recap-year-" + league.keyName) as HTMLInputElement;
        let recapWeek = document.getElementById("recap-week-" + league.keyName) as HTMLInputElement;

        if (recapYear && Number(recapYear.value) && recapWeek && Number(recapWeek.value)) {
            FantasyLeaguesService.getWeekRecapWithEspn(league.id.toString(), recapYear.value, recapWeek.value, true, espnS2.value, espnSwid.value)
                .then(res => {
                    Alert.responseCode(res);
                    Logger.debug("Got week recap");

                    if (res.status === 200) {
                        setWeek(parseInt(recapWeek.value) + 1);
                    }
                })
                .then(_ => {
                    localStorage.setItem(espnS2Key, espnS2.value);
                    localStorage.setItem(espnSwidKey, espnSwid.value);
                });
                Logger.debug("Recap input validate");
        } else {
            Logger.debug("Recap input failed");
        }
    };

    return (
        <>
            <h3>Import Week</h3>
            <form>
                <label>Year: </label>
                <input type={"text"} id={"recap-year-" + league.keyName} />
                <label>Week: </label>
                <input type={"text"} id={"recap-week-" + league.keyName} />
                <input type={"button"} onClick={() => buttonHandler()} value={"UPDATE"} />
            </form>
        </>
    );
}

const AdminLeagueSettings = () => {
    const [leagues, setLeagues] = useState<MFantasyLeague[]>();
    const [selectedLeague, setSelectedLeague] = useState<MFantasyLeague>();
    const [platforms, setPlatforms] = useState<string[]>();
    const [week, setWeek] = useState<number>();
    const [year, setYear] = useState<number>();

    useEffect(() => {
        fetchLeagues(setLeagues);
        fetchLeaguePlatforms(setPlatforms);
    }, []);

    useEffect(() => {
        if (!!selectedLeague) {
            fetchLatestScoredWeek(selectedLeague, setWeek, setYear);
        }
    }, [selectedLeague]);

    useEffect(() => {
        if (!!selectedLeague) {
            let recapYear = document.getElementById("recap-year-" + selectedLeague.keyName) as HTMLInputElement;
            recapYear.value = year ? year.toString() : (Math.floor(Date.now() / 1000 / 60 / 60 / 24 / 365) + 1970).toString();
            let recapWeek = document.getElementById("recap-week-" + selectedLeague.keyName) as HTMLInputElement;
            recapWeek.value = week ? week.toString() : "1";
        }
    }, [selectedLeague, week, year]);

    return (
        <>
            <h2>ESPN Parameters</h2>
            { renderEspnParams() }

            <h2>Edit League</h2>
            <LeagueSelectComponent leagues={leagues} setSelected={setSelectedLeague}/>
            { renderLeague(selectedLeague, year, setWeek) }

            <h2>Import League</h2>
            { renderAddLeague(platforms, setLeagues) }
        </>
    );
};

export default AdminLeagueSettings;
