import ReactPlaceholder from "react-placeholder";
import placeholder, {PPlaceholder} from "./Placeholder";

const PlaceholderListComponent = (props: PPlaceholder) => {
    return (
        <>
            <ReactPlaceholder showLoadingAnimation={true} ready={props.ready} customPlaceholder={placeholder('list', 1, 10, false)}>
                {props.content}
            </ReactPlaceholder>
        </>
    )
}

export default PlaceholderListComponent;
