import {BaseService} from "./BaseService";
import {HomeClient} from "../clients/HomeClient";

export class HomeService extends BaseService {

    public static getHealth() {
        return this.getRequest(HomeClient.getHealthUrl());
    }

}
