import React, {useEffect, useState} from "react";
import Markdown from 'markdown-to-jsx';
import {useParams} from "react-router-dom";
import {FantasyLeaguesService} from "../../service/FantasyLeaguesService";
import {Buffer} from "buffer";
import {GithubResponse} from "../../model/responses/GithubResponse";
import PlaceholderListComponent from "../placeholders/PlaceholderList.component";

interface ISetFantasyLeagueBylaws extends React.Dispatch<React.SetStateAction<string>> { }

function fetchBylaws(leagueId: string, state: string, setState: ISetFantasyLeagueBylaws) {
    setState('');

    FantasyLeaguesService.getLeague(leagueId).then(response => response.json()).then(jsonLeague => {
        return FantasyLeaguesService.getBylaws(jsonLeague.keyName);
    })
        .then(response => response.json()).then((jsonFile: GithubResponse) => {
            setState(Buffer.from(jsonFile.content, "base64").toString());
        });
}

const FantasyLeagueBylaws = () => {
    let { leagueId } = useParams();
    const [state, setState] = useState('');

    let ratifiedDate: Date | undefined;
    let updatedDate: Date | undefined;

    useEffect(() => {
        fetchBylaws(leagueId!, state, setState);
    }, []);

    function content() {
        return (
            <>
                <div className={"bylaws"}>
                    <Markdown>
                        {state}
                    </Markdown>
                </div>
                <div className={"bylaws-status"}>
                    <div>
                        Ratified: {ratifiedDate?.toISOString() ?? "N/A"}
                    </div>
                    <div>
                        Last Updated: {updatedDate?.toISOString() ?? "N/A"}
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <PlaceholderListComponent content={content()} ready={!!state}/>
        </>
    );
};

export default FantasyLeagueBylaws;
