import {MFantasyRecordsIdvYearRecap} from "../../model/responses/MFantasyRecordsIdvYearRecap";
import {ordinal} from "../../utilities";
import {WLRecord} from "../../model/WLRecord";

export function championshipStr(item: MFantasyRecordsIdvYearRecap, championships: number[] | null) {
    let result;
    if (item.wonChampionship) {
        result = 'Won';
        if (championships !== null) {
            result += ` (${ordinal(championships.indexOf(item.year) + 1)})`;
        }
    } else if (item.madeChampionship) {
        result = 'Lost';
    } else {
        result = '-';
    }
    return result;
}

export function playoffsStr(item: MFantasyRecordsIdvYearRecap) {
    if (item.madePlayoffs) {
        return `Y (${item.records.playoffRecord.wins}-${item.records.playoffRecord.losses})`;
    } else {
        return '-';
    }
}

export function recordStr(item: WLRecord) {
    return `${item.wins}-${item.losses}` + (item.ties > 0 ? `-${item.ties}` : '');
}
