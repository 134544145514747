import ReactPlaceholder from "react-placeholder";
import placeholder, {PPlaceholder} from "./Placeholder";

const PlaceholderTableComponent = (props: PPlaceholder) => {
    return (
        <>
            <ReactPlaceholder showLoadingAnimation={true} ready={props.ready} customPlaceholder={placeholder('table', 5, 5, true)}>
                {props.content}
            </ReactPlaceholder>
        </>
    )
}

export default PlaceholderTableComponent;
