import React, {Dispatch, useEffect, useState} from 'react';
import {Link, NavigateFunction, useNavigate, useParams} from "react-router-dom";
import {MFantasyManager} from "../../model/entities/MFantasyManager";
import {MFantasyRecordsPerfResponse} from "../../model/responses/MFantasyRecordsPerfResponse";
import {MFantasyMatchupPerformance} from "../../model/entities/MFantasyMatchupPerformance";
import {FantasyLeaguesService} from "../../service/FantasyLeaguesService";
import {MFantasyRecordsIdvYearRecap} from "../../model/responses/MFantasyRecordsIdvYearRecap";
import {FantasyManagersService} from "../../service/FantasyManagersService";
import {MFantasyRecordsIdvSummaryResponse} from "../../model/responses/MFantasyRecordsIdvSummaryResponse";
import {MFantasyRecordsIdvHeadToHeadResponse} from "../../model/responses/MFantasyRecordsIdvHeadToHeadResponse";
import {championshipStr, playoffsStr, recordStr} from "./FantasyFormatting";
import MenuButtonComponent from "../layout/components/MenuButtonComponent";

function fetchChampionships(managerId: string, setChampionhsips: ISetChampionships) {
    setChampionhsips([]);

    FantasyManagersService.getChampionships(managerId).then(response => response.json()).then(json => {
        setChampionhsips(json);
    });
}

function fetchHeadToHead(managerId: string, setHeadToHead: ISetHeadToHeadResponse) {
    setHeadToHead([]);

    FantasyManagersService.getHeadToHead(managerId).then(response => response.json()).then(json => {
        setHeadToHead(json);
    });
}

function fetchManagers(leagueId: string, setManagers: ISetManagers) {
    setManagers([]);

    FantasyLeaguesService.getAllManagers(leagueId).then(response => response.json()).then(json => {
        setManagers(json);
    });
}

function fetchRecordBook(managerId: string, limit: number, setMatchupsResponse: ISetMatchupsResponse) {
    setMatchupsResponse(null);

    FantasyManagersService.getRecordBook(managerId, limit).then(response => response.json()).then(json => {
        setMatchupsResponse(json);
    });
}

function fetchSummary(managerId: string, setManagerSummary: ISetManagerSummary) {
    setManagerSummary(null);

    FantasyManagersService.getOverallSummary(managerId).then(response => response.json()).then(json => {
        setManagerSummary(json);
    });
}

function fetchSeasons(managerId: string, setSeasonsResponse: ISetTeamsResponse, setSelectedManager: any) {
    setSeasonsResponse([]);

    FantasyManagersService.getSeasonRecaps(managerId).then(response => response.json()).then(json => {
        setSeasonsResponse(json);
        setSelectedManager((json as MFantasyRecordsIdvYearRecap[]).at(0)?.team.manager);
    });
}

function renderManagerList(managers: MFantasyManager[], navigate: NavigateFunction) {
    if (!managers) {
        return (
            <></>
        );
    } else {
        return (
            <ul className={'content-menu'}>
                {managers.map((item: MFantasyManager) => (
                    <MenuButtonComponent
                        className={'hover-white-secondary'}
                        content={<div>{item.displayName}</div>}
                        onClick={() => navigate("" + item.id)}
                        routeRegex={null}
                    />
                ))}
            </ul>
        );
    }
}

function renderSummary(summary: MFantasyRecordsIdvSummaryResponse | null) {
    if (!summary) {
        return (
            <></>
        );
    } else {
        return (
            <>
                <table>
                    <caption>Overall Summary</caption>
                    <thead>
                    <tr>
                        <th>Record</th>
                        <th>Wins</th>
                        <th>Losses</th>
                        <th>Ties</th>
                        <th>WPct</th>
                        <th>PF/G</th>
                        <th>PA/G</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Overall</td>
                            <td>{summary.overallRecord.wins}</td>
                            <td>{summary.overallRecord.losses}</td>
                            <td>{summary.overallRecord.ties}</td>
                            <td>{summary.overallRecord.winPercent.toFixed(3)}</td>
                            <td>{summary.overallRecord.averagePointsFor.toFixed(2)}</td>
                            <td>{summary.overallRecord.averagePointsAgainst.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Regular Season</td>
                            <td>{summary.regularSeasonRecord.wins}</td>
                            <td>{summary.regularSeasonRecord.losses}</td>
                            <td>{summary.regularSeasonRecord.ties}</td>
                            <td>{summary.regularSeasonRecord.winPercent.toFixed(3)}</td>
                            <td>{summary.regularSeasonRecord.averagePointsFor.toFixed(2)}</td>
                            <td>{summary.regularSeasonRecord.averagePointsAgainst.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Playoffs</td>
                            <td>{summary.playoffRecord.wins}</td>
                            <td>{summary.playoffRecord.losses}</td>
                            <td>{summary.playoffRecord.ties}</td>
                            <td>{summary.playoffRecord.winPercent.toFixed(3)}</td>
                            <td>{summary.playoffRecord.averagePointsFor.toFixed(2)}</td>
                            <td>{summary.playoffRecord.averagePointsAgainst.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Championships</td>
                            <td>{summary.championshipRecord.wins}</td>
                            <td>{summary.championshipRecord.losses}</td>
                            <td>{summary.championshipRecord.ties}</td>
                            <td>{summary.championshipRecord.winPercent.toFixed(3)}</td>
                            <td>{summary.championshipRecord.averagePointsFor.toFixed(2)}</td>
                            <td>{summary.championshipRecord.averagePointsAgainst.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Divisional</td>
                            <td>{summary.divisionRecord.wins}</td>
                            <td>{summary.divisionRecord.losses}</td>
                            <td>{summary.divisionRecord.ties}</td>
                            <td>{summary.divisionRecord.winPercent.toFixed(3)}</td>
                            <td>{summary.divisionRecord.averagePointsFor.toFixed(2)}</td>
                            <td>{summary.divisionRecord.averagePointsAgainst.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Non-divisional</td>
                            <td>{summary.outDivisionRecord.wins}</td>
                            <td>{summary.outDivisionRecord.losses}</td>
                            <td>{summary.outDivisionRecord.ties}</td>
                            <td>{summary.outDivisionRecord.winPercent.toFixed(3)}</td>
                            <td>{summary.outDivisionRecord.averagePointsFor.toFixed(2)}</td>
                            <td>{summary.outDivisionRecord.averagePointsAgainst.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

function renderHeadToHead(headToHead: MFantasyRecordsIdvHeadToHeadResponse[]) {
    if (!headToHead.length) {
        return (
            <></>
        );
    } else {
        return (
            <>
                <table>
                    <caption>Head-to-Head</caption>
                    <thead>
                        <tr>
                            <th>Manager</th>
                            <th>Wins</th>
                            <th>Losses</th>
                            <th>Ties</th>
                            <th>WPct</th>
                            <th>PF/G</th>
                            <th>PA/G</th>
                        </tr>
                    </thead>
                    <tbody>
                    {headToHead.map((item: MFantasyRecordsIdvHeadToHeadResponse, i: number) => (
                        <tr>
                            <td>{item.opponent.displayName}</td>
                            <td>{item.record.wins}</td>
                            <td>{item.record.losses}</td>
                            <td>{item.record.ties}</td>
                            <td>{item.record.winPercent.toFixed(3)}</td>
                            <td>{item.record.averagePointsFor.toFixed(2)}</td>
                            <td>{item.record.averagePointsAgainst.toFixed(2)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        );
    }
}

function renderSeasonsList(seasonsResults: MFantasyRecordsIdvYearRecap[], championships: number[]) {
    if (!seasonsResults.length) {
        return (
            <></>
        );
    } else {
        return (
            <>
                <table>
                    <caption>Seasons</caption>
                    <thead>
                        <tr>
                            <th>Year</th>
                            <th>Name</th>
                            <th>Record</th>
                            <th>Playoffs</th>
                            <th>Championship</th>
                        </tr>
                    </thead>
                    <tbody>
                    {seasonsResults.map((item: MFantasyRecordsIdvYearRecap, i: number) => (
                        <tr key={item.team.year}>
                            <td>
                                {item.team.year}
                            </td>
                            <td>
                                {item.team.name}
                            </td>
                            <td>
                                {recordStr(item.records.overallRecord)}
                            </td>
                            <td>
                                {playoffsStr(item)}
                            </td>
                            <td>
                                {championshipStr(item, championships)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        );
    }
}

function renderRecordBookList(matchupsResponse: MFantasyRecordsPerfResponse | null, limit: number, setLimit: ISetLimit) {
    const minLimit = 5;
    const maxLimit = 30;

    function loadFewerHandler() {
        setLimit(Math.max(minLimit, limit - 5));
    }

    function loadMoreHandler() {
        setLimit(Math.min(limit + 5, maxLimit));
    }

    const LoadMoreLinks = () => {
        return (
            <>
                {limit > minLimit &&
                    <Link to={'#'} onClick={loadFewerHandler}>Load fewer...</Link>}
                {limit < maxLimit &&
                    <Link to={'#'} onClick={loadMoreHandler}>Load more...</Link>}
            </>
        );
    }

    if (!matchupsResponse) {
        return (
            <></>
        );
    } else {
        return (
            <>
                <table>
                    <caption>Most Points</caption>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Score</th>
                            <th>Year</th>
                            <th>Week</th>
                            <th>Opponent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchupsResponse.mostPoints.map((item: MFantasyMatchupPerformance, i: number) => (
                            <tr key={item.id}>
                                <td>
                                    {i+1}
                                </td>
                                <td>
                                    {item.score.toFixed(2)}
                                </td>
                                <td>
                                    {item.scheduleWeek.year}
                                </td>
                                <td>
                                    {item.scheduleWeek.week}
                                </td>
                                <td>
                                    {item.opponentTeam.manager.displayName}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <table>
                    <caption>Fewest Points</caption>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Score</th>
                            <th>Year</th>
                            <th>Week</th>
                            <th>Opponent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchupsResponse.fewestPoints.map((item: MFantasyMatchupPerformance, i: number) => (
                            <tr key={item.id}>
                                <td>
                                    {i+1}
                                </td>
                                <td>
                                    {item.score.toFixed(2)}
                                </td>
                                <td>
                                    {item.scheduleWeek.year}
                                </td>
                                <td>
                                    {item.scheduleWeek.week}
                                </td>
                                <td>
                                    {item.opponentTeam.manager.displayName}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <table>
                    <caption>Largest Margin Win</caption>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Margin</th>
                            <th>Score</th>
                            <th>Year</th>
                            <th>Week</th>
                            <th>Opponent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchupsResponse.largestMarginWin.map((item: MFantasyMatchupPerformance, i: number) => (
                            <tr key={item.id}>
                                <td>
                                    {i+1}
                                </td>
                                <td>
                                    {Math.abs(item.score - item.opponentScore).toFixed(2)}
                                </td>
                                <td>
                                    {item.score.toFixed(2)} - {item.opponentScore.toFixed(2)}
                                </td>
                                <td>
                                    {item.scheduleWeek.year}
                                </td>
                                <td>
                                    {item.scheduleWeek.week}
                                </td>
                                <td>
                                    {item.opponentTeam.manager.displayName}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <table>
                    <caption>Largest Margin Loss</caption>
                    <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Margin</th>
                        <th>Score</th>
                        <th>Year</th>
                        <th>Week</th>
                        <th>Opponent</th>
                    </tr>
                    </thead>
                    <tbody>
                    {matchupsResponse.largestMarginLoss.map((item: MFantasyMatchupPerformance, i: number) => (
                        <tr key={item.id}>
                            <td>
                                {i+1}
                            </td>
                            <td>
                                {Math.abs(item.score - item.opponentScore).toFixed(2)}
                            </td>
                            <td>
                                {item.score.toFixed(2)} - {item.opponentScore.toFixed(2)}
                            </td>
                            <td>
                                {item.scheduleWeek.year}
                            </td>
                            <td>
                                {item.scheduleWeek.week}
                            </td>
                            <td>
                                {item.opponentTeam.manager.displayName}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <table>
                    <caption>Smallest Margin Win</caption>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Margin</th>
                            <th>Score</th>
                            <th>Year</th>
                            <th>Week</th>
                            <th>Opponent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchupsResponse.smallestMarginWin.map((item: MFantasyMatchupPerformance, i: number) => (
                            <tr key={item.id}>
                                <td>
                                    {i+1}
                                </td>
                                <td>
                                    {(item.scoreDifference).toFixed(2)}
                                </td>
                                <td>
                                    {item.score.toFixed(2)} - {item.opponentScore.toFixed(2)}
                                </td>
                                <td>
                                    {item.scheduleWeek.year}
                                </td>
                                <td>
                                    {item.scheduleWeek.week}
                                </td>
                                <td>
                                    {item.opponentTeam.manager.displayName}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <table>
                    <caption>Smallest Margin Loss/Tie</caption>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Margin</th>
                            <th>Score</th>
                            <th>Year</th>
                            <th>Week</th>
                            <th>Opponent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchupsResponse.smallestMarginLoss.map((item: MFantasyMatchupPerformance, i: number) => (
                            <tr key={item.id}>
                                <td>
                                    {i+1}
                                </td>
                                <td>
                                    {(item.scoreDifference).toFixed(2)}
                                </td>
                                <td>
                                    {item.score.toFixed(2)} - {item.opponentScore.toFixed(2)}
                                </td>
                                <td>
                                    {item.scheduleWeek.year}
                                </td>
                                <td>
                                    {item.scheduleWeek.week}
                                </td>
                                <td>
                                    {item.opponentTeam.manager.displayName}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div>
                    Limit: {limit}
                    <LoadMoreLinks/>
                </div>
            </>
        );
    }
}

type ISetChampionships = Dispatch<React.SetStateAction<number[]>>;
type ISetHeadToHeadResponse = Dispatch<React.SetStateAction<MFantasyRecordsIdvHeadToHeadResponse[]>>;
type ISetLimit = Dispatch<React.SetStateAction<number>>;
type ISetManagers = Dispatch<React.SetStateAction<MFantasyManager[]>>;
type ISetManagerSummary = Dispatch<React.SetStateAction<MFantasyRecordsIdvSummaryResponse | null>>;
type ISetMatchupsResponse = Dispatch<React.SetStateAction<MFantasyRecordsPerfResponse | null>>;
type ISetTeamsResponse = Dispatch<React.SetStateAction<MFantasyRecordsIdvYearRecap[]>>;

const FantasyLeagueManagers = () => {
    const navigate = useNavigate();
    let { leagueId, managerId } = useParams();
    const [championships, setChampionships] = useState<number[]>([]);
    const [headToHead, setHeadToHead] = useState<MFantasyRecordsIdvHeadToHeadResponse[]>([]);
    const [limit, setLimit] = useState<number>(5);
    const [managers, setManagers] = useState<MFantasyManager[]>([]);
    const [managerSummary, setManagerSummary] = useState<MFantasyRecordsIdvSummaryResponse | null>(null);
    const [recordBook, setRecordBook] = useState<MFantasyRecordsPerfResponse | null>(null);
    const [seasonResults, setSeasonResults] = useState<MFantasyRecordsIdvYearRecap[]>([]);
    const [selectedManager, setSelectedManager] = useState<MFantasyManager | undefined>();

    useEffect(() => {
        if (!managerId) {
            fetchManagers(leagueId!, setManagers);
            setChampionships([]);
            setHeadToHead([]);
            setManagerSummary(null);
            setRecordBook(null);
            setSeasonResults([]);
            setSelectedManager(undefined);
        } else {
            setSelectedManager(managers.filter(m => m.id == parseInt(managerId!)).at(0));
            fetchChampionships(managerId, setChampionships);
            fetchHeadToHead(managerId, setHeadToHead);
            fetchRecordBook(managerId, limit, setRecordBook);
            fetchSummary(managerId, setManagerSummary)
            fetchSeasons(managerId, setSeasonResults, setSelectedManager);
            setManagers([]);
        }
    }, [leagueId, managerId, limit]);

    return (
        <>

            <>
                { renderManagerList(managers, navigate) }
            </>

            {!managers.length &&
                <>
                    <h2>{selectedManager?.displayName}</h2>
                    {renderSummary(managerSummary)}
                    {renderSeasonsList(seasonResults, championships)}
                    {renderHeadToHead(headToHead)}
                    {renderRecordBookList(recordBook, limit, setLimit)}
                </>
            }

            {!managers.length && !managerSummary && !recordBook && !seasonResults.length && <div>Loading...</div>}
        </>
    )
}

export default FantasyLeagueManagers;
