import {BaseService} from "./BaseService";
import {FantasyManagersClient} from "../clients/FantasyManagersClient";

export class FantasyManagersService extends BaseService {

    public static getChampionships(managerId: string) {
        return this.getRequest(FantasyManagersClient.getChampionshipsUrl(managerId));
    }

    public static getHeadToHead(managerId: string) {
        return this.getRequest(FantasyManagersClient.getHeadToHeadUrl(managerId));
    }

    public static getOverallSummary(managerId: string) {
        return this.getRequest(FantasyManagersClient.getOverallUrl(managerId));
    }

    public static getRecordBook(managerId: string, limit: number) {
        return this.getRequest(FantasyManagersClient.getRecordBookUrl(managerId, limit.toString()))
    }

    public static getSeasonRecaps(managerId: string) {
        return this.getRequest(FantasyManagersClient.getSeasonRecapUrl(managerId, []));
    }

}
