import React from "react";


const Admin = () => {
    return (
        <>
            <h1>Admin</h1>
        </>
    );
};

export default Admin;
