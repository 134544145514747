import {FantasyClient} from "./FantasyClient";

export class FantasyLeaguesClient {

    public static leaguesRoot = FantasyClient.fantasyRoot + "/leagues";

    private static draftDest = "/draft-order";
    private static importDest = "/import";
    private static latestScoredWeekDest = "/latest-scored-week";
    private static managersDest = "/managers";
    private static recordBookDest = "/record-book";
    private static recordBookManagersDest = FantasyLeaguesClient.recordBookDest + "/manager-summary";
    private static recordBookSeasonsDest = FantasyLeaguesClient.recordBookDest + "/seasons";
    private static recordBookWeeksDest = FantasyLeaguesClient.recordBookDest + "/weeks";
    private static seasonRecapDest = "/season-recaps";
    private static standingsDest = "/standings";
    private static weekRecapDest = "/week-recap";
    private static weekRecapLatestScoredDest = FantasyLeaguesClient.weekRecapDest + "/latest-scored";

    public static weekRecapPageParam = "week";

    public static getLeaguesUrl() {
        return new URL(`${this.leaguesRoot}`)
    }

    public static getLeagueUrl(leagueId: string) {
        return new URL(`${this.leaguesRoot}/${leagueId}`);
    }

    public static getDraftUrl(leagueId: string, managerId: string | undefined, rounds: string, year: string | undefined) {
        let url = new URL(`${this.leaguesRoot}/${leagueId}${this.draftDest}`)
        url.searchParams.append("rounds", rounds);
        if (!!managerId) {
            url.searchParams.append("managerId", managerId);
        }
        if (!!year) {
            url.searchParams.append("year", year);
        }
        return url;
    }

    public static getImportUrl() {
        return new URL(`${this.leaguesRoot}${this.importDest}`);
    }

    public static getLatestScoredWeekUrl(leagueId: string) {
        return new URL(`${this.leaguesRoot}/${leagueId}${this.latestScoredWeekDest}`);
    }

    public static getManagersUrl(leagueId: string) {
        return new URL(`${this.leaguesRoot}/${leagueId}${this.managersDest}`);
    }

    public static getRecordBookUrl(leagueId: string, limit: string) {
        let url = new URL(`${this.leaguesRoot}/${leagueId}${this.recordBookDest}`);
        url.searchParams.append("limit", limit);
        return url;
    }

    public static getRecordBookManagersUrl(leagueId: string) {
        return new URL(`${this.leaguesRoot}/${leagueId}${this.recordBookManagersDest}`);
    }

    public static getRecordBookSeasonsUrl(leagueId: string, limit: string) {
        let url = new URL(`${this.leaguesRoot}/${leagueId}${this.recordBookSeasonsDest}`);
        url.searchParams.append("limit", limit);
        return url;
    }

    public static getRecordBookWeeksUrl(leagueId: string, limit: string) {
        let url = new URL(`${this.leaguesRoot}/${leagueId}${this.recordBookWeeksDest}`);
        url.searchParams.append("limit", limit);
        return url;
    }

    public static getSeasonRecapsUrl(leagueId: string) {
        return new URL(`${this.leaguesRoot}/${leagueId}${this.seasonRecapDest}`);
    }

    public static getStandingsUrl(leagueId: string) {
        return new URL(`${this.leaguesRoot}/${leagueId}${this.standingsDest}`);
    }

    public static getStandingsYearUrl(leagueId: string, year: string) {
        return new URL(`${this.leaguesRoot}/${leagueId}${this.standingsDest}/${year}`);
    }

    public static getWeekRecapUrl(leagueId: string, year: string, week: string, migrateIfNotExists: boolean) {
        let url = new URL(`${this.leaguesRoot}/${leagueId}${this.weekRecapDest}`);
        url.searchParams.append("year", year);
        url.searchParams.append("week", week);
        url.searchParams.append("migrateIfNotExists", String(migrateIfNotExists));
        return url;
    }

    public static getWeekRecapWithEspnUrl(leagueId: string, year: string, week: string,
                                          migrateIfNotExists: boolean, espnS2: string, espnSwid: string
    ) {
        let url = FantasyLeaguesClient.getWeekRecapUrl(leagueId, year, week, migrateIfNotExists);
        url.searchParams.append("espnS2", espnS2);
        url.searchParams.append("espnSwid", espnSwid);
        return url;
    }

    public static getWeekRecapLatestScoredUrl(leagueId: string, relativeWeek: number) {
        let url = new URL(`${this.leaguesRoot}/${leagueId}${this.weekRecapLatestScoredDest}`);
        url.searchParams.append(FantasyLeaguesClient.weekRecapPageParam, String(relativeWeek))
        return url;
    }
}
