export class HomeClient {

    public static homeRoot = `${process.env.REACT_APP_API_URL}`;

    private static healthDest = "/health"

    public static getHealthUrl() {
        return new URL(`${this.homeRoot}${this.healthDest}`);
    }
}
