import {ReactNode} from "react";

interface PPopupMenu {
    className: string;
    nodes: ReactNode[];
    setShowMenu: any;
}

const PopupMenuComponent = (props: PPopupMenu) => {
    const mouseLeaveHandler = () => {
        props.setShowMenu(false);
    }

    return (
        <ul className={`popup-menu ${props.className}`} onMouseLeave={mouseLeaveHandler}>
            {props.nodes.map((node: ReactNode) => {
                return node;
            })}
        </ul>
    );
}

export default PopupMenuComponent;
