export class NflClient {

    public static nflRoot = `${process.env.REACT_APP_API_URL}/v1/nfl`;

    private static scheduleYearCurrentDest = "/week-days/schedule-year/current"

    public static getScheduleYearCurrentUrl() {
        return new URL(`${this.nflRoot}${this.scheduleYearCurrentDest}`);
    }
}
