import {ReactNode} from "react";
import {useLocation} from "react-router-dom";

interface PMenuButton {
    className: string;
    content: ReactNode;
    onClick: any;
    routeRegex: RegExp | null;
}

const MenuButtonComponent = (props: PMenuButton) => {
    const { className, content, onClick, routeRegex } = props;
    const location = useLocation();

    return (
        <li className={`${className} ${routeRegex?.test(location.pathname) ? 'active-button' : ''}`} onClick={onClick}>
            {content}
            <span className={'active-indicator'}/>
        </li>
    );
}

export default MenuButtonComponent;
