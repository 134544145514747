import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {MFantasyLeague} from "../../model/entities/MFantasyLeague";
import {FantasyLeaguesService} from "../../service/FantasyLeaguesService";

function fetchLeagueInfo(leagueId: string, setLeague: ISetFantasyLeague) {
    FantasyLeaguesService.getLeague(leagueId).then(response => response.json()).then(json => {
        setLeague(json);
    });
}

function renderInfo(league: MFantasyLeague | undefined) {
    if (!league) {
        return (
            <div>Loading...</div>
        )
    } else {
        return (
            <div>
                { league.name } - { league.platform } - { league.platformKey }
            </div>
        )
    }
}

interface ISetFantasyLeague extends React.Dispatch<React.SetStateAction<MFantasyLeague | undefined>> { }

const FantasyLeague = () => {
    let { leagueId } = useParams();
    const [ league, setLeague ] = useState<MFantasyLeague>();

    useEffect(() => {
        fetchLeagueInfo(leagueId!, setLeague);
    }, [leagueId]);

    return (
        <>
            <div>
                { renderInfo(league) }
            </div>
        </>
    )
}

export default FantasyLeague;
