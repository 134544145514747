import React, {useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {MFantasyMatchup} from "../../model/entities/MFantasyMatchup";
import {MFantasyMatchupPerformance} from "../../model/entities/MFantasyMatchupPerformance";
import {FantasyLeaguesService} from "../../service/FantasyLeaguesService";
import {FantasyLeaguesClient} from "../../clients/FantasyLeaguesClient";
import {MFantasyWeekRecap} from "../../model/responses/MFantasyWeekRecap";
import MatchupsSummaryTableComponent from "./components/MatchupsSummaryTable.component";
import {ordinal} from "../../utilities";


interface IFantasyLeagueWeekRecaps {
    isLoaded: Boolean;
    recaps: MFantasyWeekRecap[];
    week: string | undefined;
    year: string | undefined;
}
interface ISetFantasyLeagueWeekRecaps extends React.Dispatch<React.SetStateAction<IFantasyLeagueWeekRecaps>> { }


function fetchMatchups(leagueId: string, currentPage: number | null, state: IFantasyLeagueWeekRecaps, setState: ISetFantasyLeagueWeekRecaps) {
    setState({
        isLoaded: false,
        recaps: [],
        week: state.week,
        year: state.year
    });

    if (currentPage == null && !!state.year && !! state.week) {
        FantasyLeaguesService.getWeekRecap(leagueId, state.year, state.week, false).then(response => response.json()).then(json => {
            setState({
                isLoaded: true,
                recaps: json,
                week: state.week,
                year: state.year
            })
        });
    } else {
        FantasyLeaguesService.getWeekRecapLatestScored(leagueId, currentPage ? currentPage : 0).then(response => response.json()).then(json => {
            setState({
                isLoaded: true,
                recaps: json,
                week: json[0].matchup.scheduleWeek.week,
                year: json[0].matchup.scheduleWeek.year
            })
        });
    }
}

function isChampionship(item: MFantasyMatchup) { return !item.isRegularSeason && item.isPlayoffs && item.isChampionship }
function isPlayoffs(item: MFantasyMatchup) { return !item.isRegularSeason && item.isPlayoffs && !item.isChampionship }
function isRegularSeason(item: MFantasyMatchup) { return item.isRegularSeason && !item.isPlayoffs && !item.isChampionship }
function isConsolation(item: MFantasyMatchup) { return !item.isRegularSeason && !item.isPlayoffs && !item.isChampionship }

function renderInfo(currentPage: number | null, state: IFantasyLeagueWeekRecaps) {
    if (!state.isLoaded) {
        return (
            <div>Loading...</div>
        );
    } else {
        return (
            <>
                <h2>{state.year} Week {state.week}</h2>
                { renderLinks(currentPage) }

                { <MatchupsSummaryTableComponent recaps={state.recaps} title={"Championship"} filter={isChampionship} /> }

                { <MatchupsSummaryTableComponent recaps={state.recaps} title={"Playoffs"} filter={isPlayoffs} /> }
                { renderByes(state) }

                { <MatchupsSummaryTableComponent recaps={state.recaps} title={"Regular Season"} filter={isRegularSeason} /> }
                <h3>Detailed Recap</h3>
                { state.recaps.map(r => renderDetailedMatchupRecap(r)) }

                { <MatchupsSummaryTableComponent recaps={state.recaps} title={"Consolation"} filter={isConsolation} /> }
            </>
        );
    }
}

function renderLinks(currentPage: number | null) {
    currentPage = currentPage ? currentPage : 0;
    const previousPage = currentPage <= 0 ? String(currentPage - 1) : '-1';
    const nextPage = String(currentPage + 1);

    return (
        <>
            <Link to={'?' + FantasyLeaguesClient.weekRecapPageParam + '=' + previousPage}>Previous Week</Link>
            {currentPage < 0 &&
                <Link to={'?' + FantasyLeaguesClient.weekRecapPageParam + '=' + nextPage}>Next Week</Link>
            }
        </>
    );
}

function renderByes(state: IFantasyLeagueWeekRecaps) {
    function hasBye(item: MFantasyMatchup) { return item.matchupPerformances.length === 1 }
    let byePerformances = state.recaps.filter((item: MFantasyWeekRecap) => { return hasBye(item.matchup) }).map((m: MFantasyWeekRecap) => m.matchup.matchupPerformances.at(0));

    function displayName(item: MFantasyMatchupPerformance | undefined) {
        if (item) {
            return <div>Bye: {item.managerTeam.manager.displayName}</div>;
        }
    }

    if (!!byePerformances) {
        return (
            <>
                <div>
                    {byePerformances.map((item: MFantasyMatchupPerformance | undefined) => (
                        displayName(item)
                    ))}
                </div>
            </>
        );
    }
}

function renderDetailedMatchupRecap(weekRecap: MFantasyWeekRecap) {
    if (!weekRecap.matchup.countInRecords) {
        return;
    }

    let sortedScoreRecaps = weekRecap.recaps.sort((a, b) => a.performance.score < b.performance.score ? 1 : -1);
    let win = sortedScoreRecaps.at(0)!;
    let lose = sortedScoreRecaps.at(1)!;

    let sortedWinsRecaps = weekRecap.recaps.sort((a, b) => a.seriesRecord.wins < b.seriesRecord.wins ? 1 : -1);
    let leadingManager = sortedWinsRecaps.at(0)!.performance.managerTeam.manager;
    let leadingRecord = sortedWinsRecaps.at(0)!.seriesRecord;

    return (
        <div>
            <div style={{fontWeight: "bold"}}>{win.performance.managerTeam.name} beat {lose.performance.managerTeam.name} {win.performance.score.toFixed(2)}-{lose.performance.score.toFixed(2)}</div>
            <div>{leadingManager.firstName} leads series {leadingRecord.wins}-{leadingRecord.losses}</div>
            <div>{win.performance.managerTeam.manager.firstName}'s {ordinal(win.mostPointsSeriesRank)} most points in series, {ordinal(win.mostPointsManagerRank)} for manager, {ordinal(win.mostPointsLeagueRank)} overall</div>
            <div>{lose.performance.managerTeam.manager.firstName}'s {ordinal(lose.mostPointsSeriesRank)} most points in series, {ordinal(lose.mostPointsManagerRank)} for manager, {ordinal(lose.mostPointsLeagueRank)} overall</div>
            <div>Series' {ordinal(weekRecap.highestScoreSeriesRank)} highest scoring game, {ordinal(weekRecap.highestScoreLeagueRank)} overall</div>
            <div>Series' {ordinal(weekRecap.smallestMarginSeriesRank)} smallest margin, {ordinal(weekRecap.smallestMarginLeagueRank)} overall</div>
            <br/>
        </div>
    );
}

const FantasyLeagueWeekRecaps = () => {
    let { leagueId, year, week } = useParams();
    // eslint-disable-next-line
    const [search, setSearch] = useSearchParams();
    const [state, setState] = useState<IFantasyLeagueWeekRecaps>({
        isLoaded: false,
        recaps: [],
        week: week,
        year: year
    })

    const currentPage = search.has(FantasyLeaguesClient.weekRecapPageParam) ? parseInt(search.get(FantasyLeaguesClient.weekRecapPageParam)!) : null;

    useEffect(() => {
        fetchMatchups(leagueId!, currentPage, state, setState);
    }, [leagueId, currentPage]);

    return (
        <>
            { renderInfo(currentPage, state) }
        </>
    );
};

export default FantasyLeagueWeekRecaps;
