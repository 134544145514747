import {firebaseAuth} from "../../firebase";
import {Logger} from "../../Logger";

export class BaseService {

    private static getHeaders() {
        return {
            'X-client-id': 'fantasy-webapp',
            'X-firebase-user-id': `${firebaseAuth.currentUser?.uid}`
        };
    }

    private static sendHeaders() {
        return {
            ...this.getHeaders(),
            'Content-Type': 'application/json'
        };
    }

    protected static getRequest(url: URL) {
        Logger.debug("GET: " + url);
        return fetch(url, {
            method: 'GET',
            headers: BaseService.getHeaders()
        });
    }

    protected static patchRequest(url: URL, body: any) {
        Logger.debug("PATCH: " + url);
        return fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: BaseService.sendHeaders()
        })
    }

    protected static postRequest(url: URL, body: any) {
        Logger.debug("POST: " + url);
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: BaseService.sendHeaders()
        })
    }

    protected static putRequest(url: URL, body: any) {
        Logger.debug("PUT: " + url);
        return fetch(url, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: BaseService.sendHeaders()
        })
    }

}
