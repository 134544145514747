import {range} from "../../utilities";
import {TextRow} from "react-placeholder/lib/placeholders";
import {ReactElement} from "react";

export interface PPlaceholder {
    content: ReactElement,
    ready: boolean
}

function placeholder(classLabel: string, cols: number, rows: number, header: boolean) {
    return (
        <div className={`placeholder-${classLabel}`}>
            {header && <div className={`placeholder-${classLabel}-row`}>
                <TextRow color={'#cdcdcd'}/>
            </div>}
            {range(1, rows).map(() => (
                <div className={`placeholder-${classLabel}-row`}>
                    {range(1, cols).map(() => (
                        <TextRow color={'#cdcdcd'}/>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default placeholder;
